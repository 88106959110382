<template>
  <div class="center-container">
    <el-row :gutter="20">

      <el-col :span="12" :offset="6">
        <el-card v-for="item in tableData" :key="item.id" style="margin: 10px 0">
          <div style="padding-left: 50px; flex: 1">
            <div style="border-bottom: 1px solid #ddd; width: 100%; padding-bottom: 10px">
              <span style="font-size: 24px"
                    @click="$router.push('/front/homeDetail?id=' + item.book.id)">{{ item.book.name }}</span>
            </div>
            <div style="line-height: 30px">
              <div><b style="margin-right: 10px">漂流时间：</b>{{ item.driftTimestamp }}</div>
              <div><b style="margin-right: 10px">状态：</b>
                <el-tag v-if="item.status==='0'" type="info">漂流中</el-tag>
                <el-tag v-if="item.status==='1'" type="warning">申请中</el-tag>
                <el-tag v-if="item.status==='2'"  type="success">漂流完成</el-tag>
<!--                <el-tag v-if="item.status==='3'" type="success">未漂流</el-tag>-->
              </div>
              <div><b style="margin-right: 10px">作者：</b>{{ item.book.author }}</div>
              <div><b style="margin-right: 10px">出版社：</b>{{ item.book.publisher }}</div>
              <div><b style="margin-right: 10px">出版年份：</b>{{ item.book.publishedYear }}</div>
              <div><b style="margin-right: 10px">联系方式：</b>{{ item.phone }}</div>
              <div><b style="margin-right: 10px">领取地址：</b>{{ item.address }}</div>
              <div><b style="margin-right: 10px">类型：</b>{{ item.book.typeText.name }}</div>
            </div>
          </div>
        </el-card>
        <div style="margin: 10px 0; padding: 10px; background-color: #fff">
          <el-pagination
              size="mid"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[2, 5, 10, 20]"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "DriftRecordBook",
  data() {
    return {
      tableData: [],
      typeBookData: [],
      total: 0,
      pageNum: 1,
      pageSize: 5,
      form: {},
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/driftRecord/my", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        }
      }).then(res => {
        this.tableData = res.data.records
        console.log(this.tableData)
        this.total = res.data.total
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    reset() {
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    }
  }
}
</script>


<style>
.center-container {
  margin: 0 auto; /* 左右居中 */
  min-height: calc(100vh - 70px);
  padding: 10px;
}
</style>
